import React, { useState, useContext } from 'react';
import { AuthContext } from './authContext'; 
import {
  AppBar, Toolbar, Button, Typography, makeStyles, IconButton, Grid,
  useMediaQuery, useTheme
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import SignupModal from './SignUp';
import Login from './Login';
import CircularProgress from '@material-ui/core/CircularProgress';
import MenuIcon from '@material-ui/icons/Menu';
import axios from 'axios';

const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '48px',
  },
  title: {
    fontFamily: '"Courier New", monospace',
    color: '#87ceeb',
    marginRight: theme.spacing(2),
    textDecoration: 'none',
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  rightSide: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    fontSize: '0.875em',
  },
}));

export default function MainAppBar({ isLoggedIn }) {
  const classes = useStyles();
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isSignupOpen, setIsSignupOpen] = useState(false);
  const [state, dispatch] = useContext(AuthContext);
  const api_url = process.env.REACT_APP_BACKEND_URL;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const openLoginModal = () => {
    setIsLoginOpen(true);
  };

  const openSignupModal = () => {
    setIsSignupOpen(true);
  };

  const handleLogout = () => {
    const api_logout_url = api_url + "/api/logout/";

    axios.post(api_logout_url, {}, { withCredentials: true })
    .then(response => {
      dispatch({ type: 'LOGOUT' });
    })
    .catch(error => {
      console.error('Error during logout:', error);
    });
  };

  return (
    <AppBar position="static">
      <Toolbar className={classes.toolbar} style={{backgroundColor: '#3a3a3a'}}>
        <div className={classes.leftSide}>
          <Typography variant="h6" className={classes.title} component={RouterLink} to="/">
            {"< PROMPTSCORE />"}
          </Typography>
          {!isMobile && (
            <Button color="inherit" component={RouterLink} to="/">
              Problems
            </Button>
          )}
        </div>
        <div className={classes.leftSide}>
          {state.loading ? (
            <CircularProgress color="inherit" />
          ) : state.isAuthenticated ? (
            <Button color="inherit" onClick={handleLogout}>Sign Out</Button>
          ) : (
            <>
              <SignupModal isOpen={isSignupOpen} onClose={() => setIsSignupOpen(false)} />
              <Button color="inherit" className={classes.button} onClick={openSignupModal}>Register</Button>
              <Button color="inherit" className={classes.button} onClick={openLoginModal}>Sign In</Button>
              <Login isOpen={isLoginOpen} onClose={() => setIsLoginOpen(false)} openSignupModal={openSignupModal} />
            </>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
}
