// import React, { useState } from 'react';
// import { Button, Modal, TextField, Typography } from '@material-ui/core';
// import { makeStyles } from '@material-ui/core/styles';

import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from './authContext'; 
import { Button, Modal, DialogContent, DialogTitle, TextField, makeStyles, Grid, Typography, Paper } from '@material-ui/core';
import axios from 'axios';
import Login from './Login';
axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  feedbackForm: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

export default function Feedback() {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState('');
  const classes = useStyles();
  const api_url = process.env.REACT_APP_BACKEND_URL;

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFeedbackChange = (event) => {
    setFeedback(event.target.value);
  };

  const handleSubmit = async (event) => {

    event.preventDefault();
    const feedback_url = api_url + "/api/userfeedback/";
    const page_url = window.location.href;

    try {
      const response = await axios.post(feedback_url, {feedback: feedback, page_url: page_url}, {
          withCredentials: true
        });
        console.log(response.data);
    } catch (error) {
        console.error('There was an error!', error);
    }
    console.log(feedback);
    setFeedback('');
    handleClose();
  };

  const body = (
    <div className={classes.paper}>
      <Typography variant="h6" id="modal-title">
        Send us your feedback
      </Typography>
      <form className={classes.feedbackForm} onSubmit={handleSubmit}>
        <TextField
          id="feedback-input"
          label="Feedback"
          multiline
          rows={4}
          value={feedback}
          onChange={handleFeedbackChange}
          variant="outlined"
          margin="normal"
          required
          fullWidth
        />
        <Button type="submit" variant="contained" color="primary">
          Submit Feedback
        </Button>
      </form>
    </div>
  );

  return (
    <div>
      <Button type="button" onClick={handleOpen}>
        Feedback
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="feedback-input"
        className={classes.modal}
      >
        {body}
      </Modal>
    </div>
  );
}
