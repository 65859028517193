import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from './authContext'; 
import axios from 'axios';
import { Button, Typography, makeStyles, Grid, Paper, CircularProgress } from '@material-ui/core';

// Define your styles here. These are just examples and should be updated to match your design.
const useStyles = makeStyles((theme) => ({
    leaderboardContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        borderRadius: '5px',
        padding: theme.spacing(2),
        
    },
    topBar: {
        display: 'flex',
        alignItems: 'center',
        backgroundColor: '#3a3a3a',
        color: '#fff',
        borderRadius: '5px',
        justifyContent: 'center'
    },
    buttonGroup: {
        '& > *': {
          margin: theme.spacing(0.5),
          fontSize: '0.8rem',
        },
    },
    leaderboardTable: {
        padding: theme.spacing(1),
        textAlign: 'center',
    },
    userRank: {
        marginTop: theme.spacing(2),
        textAlign: 'center',
    },
}));

const LeaderBoard = () => {
  const classes = useStyles();
  const [leaderboardData, setLeaderboardData] = useState([]);
  const [currentUserProfile, setCurrentUserProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [state] = useContext(AuthContext);
  const api_url = process.env.REACT_APP_BACKEND_URL;

  let userIsLoggedIn = state.isAuthenticated;

  useEffect(() => {
    let profile_api_url = api_url + "/api/userprofiles/";
    axios.get(profile_api_url)  // Use your API endpoint
      .then((response) => {
        setLeaderboardData(response.data.results);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
    let me_api_url = api_url + "/api/userprofiles/me/";
    axios.get(me_api_url)
      .then((response) => {
            setCurrentUserProfile(response.data);
          console.log(currentUserProfile);
      })
      .catch((error) => {
          console.error('Error:', error);
      });
  }, []);

  const getMedal = (index) => {
    switch (index) {
      case 0:
        return "🥇";
      case 1:
        return "🥈";
      case 2:
        return "🥉";
      default:
        return "";
    }
  };

  return (
    <div className={classes.leaderboardContainer}>
        <Paper className={classes.problemDescription}>
            <div className={classes.topBar}>
                <div className={classes.buttonGroup}>
                    <Button color="default">
                        Leaderboard
                    </Button>
                </div>
            </div>

            {isLoading ? (
              <Typography>Loading...</Typography>
            ) : error ? (
              <Typography>Error: {error}</Typography>
            ) : (
              <>
                {/* Map through the leaderboardData and display each item. */}
                <table className={classes.leaderboardTable}>
                    <thead>
                        <tr>
                        <th>User</th>
                        <th>Score</th>
                        </tr>
                    </thead>
                    <tbody>
                        {leaderboardData.slice(0, 10).map((user, index) => (
                        <tr key={user.user}>
                            <td>{user.username} {getMedal(index)}</td>
                            <td>{user.total_score}</td>
                        </tr>
                        ))}
                    </tbody>
                </table>

                {/* Separator */}
                <hr />

                {/* Current user's profile */}
                {currentUserProfile && (
                    <div className={classes.userRank}>
                        <table className={classes.leaderboardTable}>
                            <thead>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{currentUserProfile.username}</td>
                                    <td>{currentUserProfile.total_score}</td>
                                    <td>Rank: {currentUserProfile.rank}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                )}

              </>
            )}
          </Paper>
      
    </div>
  );
};


export default LeaderBoard;
