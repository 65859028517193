import axios from 'axios';
import React, { useEffect, useContext, useRef } from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { AuthContext } from './authContext';

const clientId = "506325779237-rd705er279tkrq4abfmnla9itagljgk5.apps.googleusercontent.com";

function GoogleLoginButton() {
  const [, dispatch] = useContext(AuthContext);

    const onSuccess = async (credentialResponse) => {
        console.log('Login Success:', credentialResponse);
        // You can call your backend API here to log in the user
        try {
            const response = await axios.post('https://backend.promptscore.dev/dj-rest-auth/google/', {
                access_token: credentialResponse.credential,
            });
            console.log(response.data); // You should see your user info here
            dispatch({ type: 'LOGIN' });
            // You might want to store the session token or any authentication-related data returned from your server
        } catch (error) {
            console.error('Error during API call:', error);
        }
    };

    const onError = () => {
        console.log('Login Failed');
    };

    return (
        <GoogleOAuthProvider clientId={clientId}>
            <GoogleLogin
                onSuccess={onSuccess}
                onError={onError}
            />
        </GoogleOAuthProvider>
    );
}

export default GoogleLoginButton;