import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from './authContext'; 
import { AppBar, Toolbar, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Select, MenuItem, makeStyles, Divider, Typography, Grid, FormControl, InputLabel } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MainAppBar from './MainAppBar';
import CheckIcon from '@material-ui/icons/Check';
import axios from 'axios';
import Pagination from '@material-ui/lab/Pagination';
import LeaderBoard from './LeaderBoard'; 


const useStyles = makeStyles((theme) => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: '48px', 
  },
  tableContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(8),
    width: '100%',
    boxSizing: 'border-box',
    overflowX: 'hidden',
    padding: theme.spacing(2),
  },
  searchCriteria: {
    marginBottom: theme.spacing(2), // Adjust this value to bring search bar closer to table
    width: '100%', 
  },
  tableWrapper: {
    display: 'flex',
    justifyContent: 'center',
    boxSizing: 'border-box',
    overflowX: 'hidden',
  },
  tableHeaderCell: {
    color: '#c1c1c1',
  },
  title: {
    fontFamily: '"Courier New", monospace',  
    color: '#87ceeb',
    marginRight: theme.spacing(2),
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center', 
  },
  tableRow: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    marginTop: theme.spacing(0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  link: {
    color: 'inherit',
    textDecoration: 'inherit',
    '&:hover': {
      color: '#87ceeb', // Color when hovered
    },
  },
  paginationContainer: {
    marginTop: theme.spacing(2),  // Add a bit of spacing at the top
  },
  leaderboardContainer: {
    marginTop: theme.spacing(2),
    height: '55vh',
  },
}));


const MainPage = () => {
  const classes = useStyles();
  const [problems, setProblems] = useState({results: [], next: null, previous: null});
  const [state] = useContext(AuthContext);
  let userIsLoggedIn = state.isAuthenticated;

  const [difficulty, setDifficulty] = useState('');
  const [tag, setTag] = useState('');
  const [name, setName] = useState(''); 
  const debouncedSearchTerm = useDebounce(name, 500); // Debounce time of 500ms
  const [page, setPage] = useState(1); 
  const [pageCount, setPageCount] = useState(0); 
  const api_url = process.env.REACT_APP_BACKEND_URL;
  const itemsPerPage = 10;



  function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
  
    useEffect(
      () => {
        const handler = setTimeout(() => {
          setDebouncedValue(value);
        }, delay);
  
        return () => {
          clearTimeout(handler);
        };
      },
      [value, delay] 
    );
  
    return debouncedValue;
  }

  useEffect(() => {
    // Build the GET parameters
    let params = new URLSearchParams();
    if (difficulty) params.append('difficulty', difficulty);
    if (tag) params.append('tag', tag);
    if (debouncedSearchTerm) params.append('name', debouncedSearchTerm);
    params.append('page', page);
    const api_problems_url = api_url + "/api/problems?" + params.toString();

    axios.get(api_problems_url)
      .then((response) => {
        setProblems(response.data); 
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }, [debouncedSearchTerm, tag, difficulty, page]);

  const handleDifficultyChange = (event) => {
    setDifficulty(event.target.value);
  };

  const handleTagChange = (event) => {
    setTag(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    // Fetch new data based on the new page value
  };

  return (
    <>
     <MainAppBar isLoggedIn={userIsLoggedIn} />

<Grid container className={classes.tableWrapper}>
    <Grid item xs={12} md={6} lg={6}>
        <TableContainer className={classes.tableContainer}>
            <Grid container className={classes.searchCriteria} spacing={2}>

                <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl} fullWidth>

                    <TextField label="Search" value={name} onChange={(e) => setName(e.target.value)} fullWidth />
                    </FormControl>

                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="difficulty-label">Difficulty</InputLabel>
                        <Select
                            labelId="difficulty-label"
                            value={difficulty}
                            onChange={handleDifficultyChange}
                        >
                            <MenuItem value=""><em>All</em></MenuItem>
                            <MenuItem value={'E'}>Easy</MenuItem>
                            <MenuItem value={'M'}>Medium</MenuItem>
                            <MenuItem value={'H'}>Hard</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={6} sm={6} md={4}>
                    <FormControl className={classes.formControl} fullWidth>
                        <InputLabel id="tag-label">Tags</InputLabel>
                        <Select
                            labelId="tag-label"
                            value={tag}
                            onChange={handleTagChange}
                        >
                            <MenuItem value={''}><em>All</em></MenuItem>
                            <MenuItem value={'extraction'}>Extraction</MenuItem>
                            <MenuItem value={'categorization'}>Categorization</MenuItem>
                            <MenuItem value={'translation'}>Translation</MenuItem>
                            <MenuItem value={'actionable'}>Actionable</MenuItem>
                            <MenuItem value={'processing'}>Processing</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>

            <Divider style={{ margin: '20px 0' }} />

            <Table>
                <TableHead className={classes.tableHeader}>
                    <TableRow>
                        <TableCell className={classes.tableHeaderCell}>Problem Name</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Difficulty</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Tag</TableCell>
                        <TableCell className={classes.tableHeaderCell}>Completed</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {problems.results.map((problem, index) => (
                        <TableRow key={problem.id} className={classes.tableRow}>
                            <TableCell>
                                <Link to={`/problem/${problem.slug}`} className={classes.link}>
                                    <Typography className={classes.link}>
                                        {problem.name}
                                    </Typography>
                                </Link>
                            </TableCell>
                            <TableCell>{problem.difficulty}</TableCell>
                            <TableCell>{problem.tag_display}</TableCell>
                            <TableCell>{problem.completed && <CheckIcon style={{ color: 'green' }} />}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Grid 
                container 
                className={classes.paginationContainer} 
                justify="flex-end" 
                alignItems="center" 
            >
                <Pagination 
                  count={Math.ceil(problems.count / itemsPerPage)} 
                  page={page} 
                  onChange={handlePageChange} 
                  showFirstButton 
                  showLastButton 
              />
            </Grid>
        </TableContainer>
    </Grid>
    
    <Grid item xs={12} md={6} lg={3} className={classes.leaderboardContainer}>
        <LeaderBoard />
    </Grid>
</Grid>
</>
  );
};

export default MainPage;
