import { Routes, Route } from 'react-router-dom';
import MainPage from './MainPage';
import ProblemDetailPage from './ProblemDetailPage';
import { AuthProvider } from './authContext';
import React, { useContext, useEffect, Suspense } from 'react';
import { AuthContext } from './authContext';
import axios from 'axios';

function App() {
  const [state, dispatch] = useContext(AuthContext);
  const api_url = process.env.REACT_APP_BACKEND_URL;
  


  useEffect(() => {
    axios.defaults.withCredentials = true;
    let final_api_url = api_url + "/api/check-auth/";
    axios.get(final_api_url)
    .then(response => {
      // If the token is valid, update your state.
      if (response.data.isAuthenticated) {
        dispatch({ type: "LOGIN" });
      } else {
        dispatch({ type: "LOGOUT" });
      }
    })
    .catch(error => {
      // If an error occurs, assume the user is not authenticated.
      dispatch({ type: "LOGOUT" });
    });
  }, []); // Pass an empty array to only run this effect on mount.

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {!state.loading && (
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/problem/:slug" element={<ProblemDetailPage />} />
        </Routes>
      )}
    </Suspense>
  );
}

function AuthApp() {
  return (
    <AuthProvider>
      <App />
    </AuthProvider>
  );
}

export default AuthApp;
