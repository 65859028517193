import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from './authContext'; 
import Feedback from './Feedback';
import { useParams } from 'react-router-dom';
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, Button, Typography, Tooltip, makeStyles, Grid, Paper, TextareaAutosize, CircularProgress } from '@material-ui/core';
import { TableFooter, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, FormControl, InputLabel, Select, MenuItem, TextField } from '@material-ui/core';
import MainAppBar from './MainAppBar';
import axios from 'axios';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactDiffViewer, { DiffMethod } from 'ab-react-diff-viewer';


const useStyles = makeStyles((theme) => ({
    iconClicked: {
      color: 'green', /* changes color to green */
      transform: 'scale(1.1)', /* makes the icon slightly larger */
    },
    toolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: '48px',
      },
      title: {
        fontFamily: '"Courier New", monospace',
        color: '#87ceeb',
        marginRight: theme.spacing(2),
      },
      leftSide: {
        display: 'flex',
        alignItems: 'center',
      },
      problemDescription: {
        overflowY:'scroll',
        height: '75vh',
        margin: theme.spacing(2),
        borderRadius: '5px', 
      },
      promptArea: {
        display: 'flex',
        flexDirection: 'column',
        height: '35vh',
        margin: theme.spacing(2),
        borderRadius: '5px', 
      },
      topBar: {
        flex: '0 0 auto',
        backgroundColor: '#3a3a3a',
        paddingLeft: theme.spacing(1),
        boxShadow: '0 -1px 0 rgba(255, 255, 255, 0.3) inset',
        borderTopLeftRadius: '5px', 
        borderTopRightRadius: '5px', 
      },
      buttonGroup: {
        textAlign: 'left',
        '& > *': {
          margin: theme.spacing(0.5),
          fontSize: '0.8rem',
        },
      },
      activeTab: {
        borderBottom: '2px solid white',
      },
      promptContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 auto',
        padding: theme.spacing(2),
      },
      promptInput: {
        flex: '1 1 auto',
        backgroundColor: '#424242',
        color: '#fff',
        fontFamily: '"Courier New", monospace',
        fontSize: '16px',
        border: 'none',
        outline: 'none',
        resize: 'none',
        overflowY: 'scroll',
        whiteSpace: 'pre-wrap',
        wordWrap: 'break-word',
        height: '35vh',

      },
      consoleArea: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '35vh',
        margin: theme.spacing(2),
        borderRadius: '5px', 
      },
      footer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: '#3a3a3a',
        padding: theme.spacing(1),
      },
      buttonContainer: {
        display: 'flex',
        alignItems: 'center',
      },
      exampleContainer: {
        backgroundColor: '#555555',
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
      content: {
        paddingBottom: '5vh', // Adjust according to the height of your footer.
      },
      contentArea: {
        overflowY: 'auto', 
      },
      modelContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        gap: '20px',
        padding: theme.spacing(2),
      },
    paper: {
      [theme.breakpoints.down('xs')]: {
        height: 'auto',  // This is for mobile screens
      },
    },
    tableHeader: {
      color: 'white',
    },
    tableCell: {
      padding: '10px',
    },
    table: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    tableContainer: {
      display: 'flex',
      flexDirection: 'column',
      height: '92%', // or set a specific height in px or vh units
    },
    table: {
      flex: 1,
    },
    
}));


const ProblemDetailPage = () => {
  const classes = useStyles();
  const [activeProblemTab, setActiveProblemTab] = React.useState('description');
  const [activePromptTab, setActivePromptTab] = React.useState('prompt');
  const [activeConsoleTab, setActiveConsoleTab] = React.useState('testcase');

  const handleProblemTabChange = (newTab) => {
    setActiveProblemTab(newTab);
  }
  const handlePromptTabChange = (newTab) => {
    setActivePromptTab(newTab);
  }
  const handleConsoleTabChange = (newTab) => {
    setActiveConsoleTab(newTab);
  }

  const { slug } = useParams();
  const [problemData, setProblemData] = useState(null);
  const [problemCompleted, setProblemCompleted] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [state] = useContext(AuthContext);
  let userIsLoggedIn = state.isAuthenticated;
  const [userPrompt, setUserPrompt] = useState("");
  const [clickedRowIndex, setClickedRowIndex] = useState(null);

  const [loading, setLoading] = useState(false);
  const [testcaseResults, setTestcaseResults] = useState({});
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitResult, setSubmitResult] = useState(null);
  const [problemLeaderboard, setProblemLeaderboard] = useState([]);
  const [model, setModel] = useState("GPT 3.5");
  const [temperature, setTemperature] = useState(0.0);
  const [problemSubmissions, setProblemSubmissions] = useState({
    data: [],
    count: 0,
    next: null,
    previous: null,
  });

  const api_url = process.env.REACT_APP_BACKEND_URL;

  const movePromptToEditor = (prompt, index) => {
    setUserPrompt(prompt);
    setClickedRowIndex(index); // Set the clicked row index

    // Reset the clicked row index after 250ms
    setTimeout(() => {
      setClickedRowIndex(null);
    }, 500);
  }

  const handleNextClick = async () => {
    const api_problem_next = api_url + "/api/problems/next/";
    const response = await axios.get(api_problem_next, {}, {
      withCredentials: true
    });
    // redirect to slug from response
    window.location.href = "/problem/" + response.data.slug;
  }

  const handleSubmitClick = async () => {
    if (userPrompt === '') {
      toast.error('Prompt is required');
      return;
    }
    setSubmitLoading(true);
    try {
      const api_problem_submit = api_url + "/api/problems/"+slug+"/submit/";

      const response = await axios.post(api_problem_submit, { prompt: userPrompt, model: model, temperature: temperature }, {
        withCredentials: true
      });
      setSubmitResult(response.data);
      handleConsoleTabChange('result');
    } catch (error) {
      console.error(error);
    }
    setSubmitLoading(false);
  };

  const handleRunClick = async () => {
    setTestcaseResults({});
    if (userPrompt === '') {
      toast.error('Prompt is required');
      return;
    }
    setLoading(true);
    handleConsoleTabChange('testcase');
    if (problemData && problemData.testcases) {
      let results = {};
      for (const testcase of problemData.testcases) {
        try {
          let api_problem_run = api_url + "/api/problems/"+slug+"/testcases/"+testcase.id+"/run/";
          const response = await axios.post(api_problem_run, { prompt: userPrompt, model: model, temperature: temperature }, {
            withCredentials: true
          });
          console.log(response.data); 
          results[testcase.id] = response.data;
          setTestcaseResults(prevResults => ({
            ...prevResults,
            [testcase.id]: response.data
          }));
        } catch (error) {
          console.error(error);
        }
      }
    }
    setLoading(false);
  };

  function handleNextPage() {
    if (problemSubmissions.next) {
      axios.get(problemSubmissions.next)
        .then(res => {
          setProblemSubmissions({
            data: res.data.results,
            count: res.data.count,
            next: res.data.next,
            previous: res.data.previous
          });
        })
        .catch(err => {
          setError(err.message);
        });
    }
  }
  
  function handlePreviousPage() {
    if (problemSubmissions.previous) {
      axios.get(problemSubmissions.previous)
        .then(res => {
          setProblemSubmissions({
            data: res.data.results,
            count: res.data.count,
            next: res.data.next,
            previous: res.data.previous
          });
        })
        .catch(err => {
          setError(err.message);
        });
    }
  }
  

  
  useEffect(() => {
    let api_problem_slug = api_url + "/api/problems/?slug="+slug;

    axios.get(api_problem_slug)
      .then(res => {
        setProblemData(res.data.results[0]);
        setProblemCompleted(res.data.results[0].completed)
        setIsLoading(false);
      })
      .catch(err => {
        setError(err.message);
        setIsLoading(false);
      });

    let api_problem_leaderboard = api_url + "/api/problems/"+slug+"/leaderboard/";
    axios.get(api_problem_leaderboard)
      .then(res => {
        setProblemLeaderboard(res.data);
      })
      .catch(err => {
        setError(err.message);
      });

    let api_problem_submissions = api_url + "/api/problems/"+slug+"/submissions/";
    axios.get(api_problem_submissions)
    .then(res => {
      setProblemSubmissions({
        data: res.data.results,
        count: res.data.count,
        next: res.data.next,
        previous: res.data.previous
      });
    })
    .catch(err => {
      setError(err.message);
    });
  }, [slug]);

  return (
    <>
      <MainAppBar isLoggedIn={userIsLoggedIn} />
      <ToastContainer />
      <div className={classes.content}>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
        <Paper className={`${classes.paper} ${classes.problemDescription}`}>
            <div className={classes.topBar}>
                <div className={classes.buttonGroup}>
                    <Button 
                    className={activeProblemTab === 'description' ? classes.activeTab : ""} 
                    color="default"
                    onClick={() => handleProblemTabChange('description')}
                    >
                    Description
                    </Button>
                    <Button 
                    className={activeProblemTab === 'leaderboard' ? classes.activeTab : ""} 
                    color="default"
                    onClick={() => handleProblemTabChange('leaderboard')}
                    >
                    Leaderboard
                    </Button>
                    <Button 
                    className={activeProblemTab === 'submissions' ? classes.activeTab : ""} 
                    color="default"
                    onClick={() => handleProblemTabChange('submissions')}
                    >
                    Your Submissions
                    </Button>
                </div>
            </div>

            {isLoading ? (
              <Typography>Loading...</Typography>
            ) : error ? (
              <Typography>Error: {error}</Typography>
            ) : (
              <>
                {activeProblemTab === 'description' && (
                  <>
                    <Typography variant="h5" style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
                        {problemData.name}   
                        {problemCompleted && <span style={{ color: 'green' }}> (Complete)</span>}
                    </Typography>
                    <Typography variant="body1" style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
                        Difficulty: {problemData.difficulty}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
                        {problemData.description}
                    </Typography>
                    <Typography variant="body2" style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
                        Example:
                        <Paper className={`${classes.paper} ${classes.exampleContainer}`}>
                            <Typography variant="body2" style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
                                {problemData.example_question}
                            </Typography>
                        </Paper>
                        Answer:
                        <Paper className={`${classes.paper} ${classes.exampleContainer}`}>
                            <Typography variant="body2" style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
                                {problemData.example_answer}
                            </Typography>
                        </Paper>
                    </Typography>
                  </>
                )}

              {activeProblemTab === 'leaderboard' && (
                <TableContainer component={Paper} className={classes.table}>
                  <Table aria-label="simple table">
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell className={classes.tableCell} style={{fontWeight: "bold"}} align="center">User</TableCell>
                        <TableCell className={classes.tableCell} style={{fontWeight: "bold"}} align="center">Score</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {problemLeaderboard.map((row) => (
                        <TableRow key={row.user__username}>
                          <TableCell align="center" component="th" scope="row" className={classes.tableCell}>
                            {row.user__username}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>{row.max_score}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              {activeProblemTab === 'submissions' && (
                <TableContainer component={Paper} className={classes.tableContainer}>
                  <Table aria-label="simple table" className={classes.table}>
                    <TableHead className={classes.tableHeader}>
                      <TableRow>
                        <TableCell className={classes.tableCell} style={{fontWeight: "bold"}} align="center">Prompt</TableCell>
                        <TableCell className={classes.tableCell} style={{fontWeight: "bold"}} align="center">Date</TableCell>
                        <TableCell className={classes.tableCell} style={{fontWeight: "bold"}} align="center">Score</TableCell>
                        <TableCell className={classes.tableCell} style={{fontWeight: "bold"}} align="center">Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Array.isArray(problemSubmissions.data) && problemSubmissions.data.map((row, index) => (
                        <TableRow key={row.user + index}>
                          <TableCell align="center" className={classes.tableCell}>
                            {row.prompt}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>
                            {row.created_at}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>
                            {row.score}
                          </TableCell>
                          <TableCell align="center" className={classes.tableCell}>
                            {index === clickedRowIndex ? (
                              <CircularProgress style={{color:"white"}} size={24} />
                              ) : (
                              <ArrowForwardIcon 
                                onClick={() => movePromptToEditor(row.prompt, index)} 
                                style={{ cursor: 'pointer' }} 
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>

                    <TableFooter>
                      <TableRow>
                        <TableCell colSpan={5}>
                          <Button disabled={!problemSubmissions.previous} onClick={handlePreviousPage}>
                            Previous Page
                          </Button>
                          <Button disabled={!problemSubmissions.next} onClick={handleNextPage}>
                            Next Page
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableFooter>

                  </Table>
                </TableContainer>
              )}
              </>
            )}
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
            <Paper className={`${classes.paper} ${classes.promptArea}`}>

                <div className={classes.topBar}>
                    <div className={classes.buttonGroup}>
                        <Button 
                            className={activePromptTab === 'prompt' ? classes.activeTab : ""} 
                            color="default"
                            onClick={() => handlePromptTabChange('prompt')}
                            >
                            Prompt
                            </Button>
                    </div>
                </div>
                <div className={classes.promptContainer}>
                    <TextareaAutosize
                    minRows={5}
                    placeholder="Write your prompt here"
                    className={classes.promptInput}
                    onChange={(e) => setUserPrompt(e.target.value)}
                    value={userPrompt}
                    />
                </div>
                <div className={classes.modelContainer}>
                <Tooltip title="Only GPT3.5 supported for now" arrow>
                  <FormControl disabled>
                    <InputLabel id="model-label">Model</InputLabel>
                    <Select
                      labelId="model-label"
                      id="model-select"
                      value={model}
                      onChange={(event) => setModel(event.target.value)}
                    >
                      <MenuItem value="GPT 3.5">GPT 3.5</MenuItem>
                    </Select>
                  </FormControl>
                  </Tooltip>
                  <TextField style={{width: '120px'}}
                    label="Temperature" 
                    defaultValue={0.0}
                    type="number"
                    value={temperature}
                    onChange={(event) => setTemperature(event.target.value)}
                    InputProps={{ inputProps: { min: 0, max: 1, step: 0.1 }}}
                  />
                </div>
            </Paper>
          <Paper className={`${classes.paper} ${classes.consoleArea}`}>

          <div className={classes.topBar}>
              <div className={classes.buttonGroup}>
                <Button 
                    className={activeConsoleTab === 'testcase' ? classes.activeTab : ""} 
                    color="default"
                    onClick={() => handleConsoleTabChange('testcase')}
                    >
                    Testcase
                    </Button>
                    <Button 
                    className={activeConsoleTab === 'result' ? classes.activeTab : ""} 
                    color="default"
                    onClick={() => handleConsoleTabChange('result')}
                    disabled={submitResult ? false : true}
                    >
                    Result
                </Button>
              </div>
            </div>
            <div className={classes.contentArea}>

                {activeConsoleTab === 'testcase' && (
                isLoading ? (
                  <Typography>Loading...</Typography>
                ) : error ? (
                  <Typography>Error: {error}</Typography>
                ) : (
                  <>
                    {problemData.testcases.map((testcase, index) => (
                      <ExpansionPanel key={index}>
                      <ExpansionPanelSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls={`panel${index}-content`}
                          id={`panel${index}-header`}
                      >
                          <Typography style={{marginRight: '5px'}}>{`Test Case ${index + 1}`}</Typography>
                          {loading && !testcaseResults[testcase.id] ? (
                            <CircularProgress  style={{ color: 'white' }} size={20} />
                          ) : (
                            testcaseResults[testcase.id] && (
                              testcaseResults[testcase.id].success ? <CheckIcon style={{ color: 'green' }} /> : <CloseIcon style={{ color: 'red' }} />
                            )
                          )}


                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails style={{paddingTop:'0'}}>
                          <Grid container spacing={0}>
                              <Grid item xs={12} sm={12}>
                                  
                                  <Paper style={{ textAlign: 'center' }} className={classes.exampleContainer}>
                                    <h4>Test Input: {testcase.text}</h4> 
                                  </Paper>

                                  {testcaseResults[testcase.id] && (
                                  <div>
                                      <ReactDiffViewer
                                        leftTitle="Your Output" 
                                        rightTitle="Expected Output" 
                                        useDarkTheme={true} 
                                        showDiffOnly={false}
                                        hideLineNumbers={true}
                                        compareMethod={DiffMethod.CHARS}
                                        oldValue={testcaseResults[testcase.id].result} 
                                        newValue={testcase.expected_result} 
                                        splitView={true} 
                                      />
                                  </div>
                                  )}
                              </Grid>
                          </Grid>
                      </ExpansionPanelDetails>
                      </ExpansionPanel>
                    ))}
                  </>
                )
              )}


              {activeConsoleTab === 'result' && submitResult && (
                <Typography variant="body2" style={{ marginBottom: '10px', marginTop: '10px', marginLeft: '10px', marginRight: '10px' }}>
                    Submission Result:
                    <Paper className={`${classes.paper} ${classes.exampleContainer}`}>

                        {submitResult && (
                            <div>
                                <Typography variant="body2">Score: {submitResult.score}</Typography>
                                <Typography variant="body2">Passed Test Cases: {submitResult.successful_test_cases} out of {submitResult.total_test_cases}</Typography>
                                <Tooltip title="Reduce the number of words in the prompt to increase your score" arrow>
                                    <Typography variant="body2">Tokens: {submitResult.prompt_tokens}</Typography>
                                </Tooltip>
                            </div>
                        )}
                    </Paper>
                </Typography>
              )}
          </div>
         
          </Paper>
          
        </Grid>
      </Grid>
      </div>
      <div className={classes.footer}>
        <div>
          <Feedback></Feedback>
        </div>
            <div className={classes.buttonContainer}>
              <Button 
                      variant="contained" 
                      color="primary" 
                      style={{ marginRight: '10px' }} 
                      onClick={handleRunClick} 
                      disabled={loading}>
                      {loading ? <CircularProgress style={{ color: 'white' }} size={20}  /> : "Run"}
              </Button>
              {userIsLoggedIn ? (
                  <>
                  <Button 
                      variant="contained" 
                      color="secondary"
                      onClick={handleSubmitClick} 
                      disabled={submitLoading}>
                      {submitLoading ? <CircularProgress style={{ color: 'white' }} size={20}  /> : "Submit"}
                  </Button>
                  </>
              ) : (
                  <>
                  <Tooltip title="You need to sign in to submit your prompt" arrow>
                      <span>
                      <Button variant="contained" color="secondary" disabled>
                          Submit
                      </Button>
                      </span>
                  </Tooltip>
                  </>
              )}
               <Button 
                      variant="contained" 
                      style={{ marginLeft: '10px' }} 
                      onClick={handleNextClick}>
                        Next
                  </Button>
              </div>
            </div>
    </>
  );
};

export default ProblemDetailPage;
