import React, { useState, useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, makeStyles, Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import { AuthContext } from './authContext';
import GoogleLoginButton from './GoogleLoginButton';


const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingTop: theme.spacing(2),
        backgroundColor: '#424242',
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(2),
        justifyContent: 'center',
    },
    loginButton: {
        backgroundColor: '#87ceeb',
        '&:hover': {
            backgroundColor: '#6495ed',
        },
    },
    signupButton: {
        color: '#87ceeb',
        '&:hover': {
            color: '#6495ed',
        },
    },
    dividerContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2, 0),
    },
    dividerBorder: {
        borderBottom: '1px solid white',
        width: '50%',
    },
    dividerText: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: 'white',
    },
}));

const Login = ({ isOpen, onClose, openSignupModal }) => {
    const classes = useStyles();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [, dispatch] = useContext(AuthContext);
    const api_url = process.env.REACT_APP_BACKEND_URL;

    const handleSubmit = async (event) => {
        event.preventDefault();
        handleLogin(username, password);
    };

    const resetPassword = () => {
        // Implement password reset functionality here
    };

    const handleOpenSignup = () => {
        onClose();
        openSignupModal();
    };

    const handleLogin = async (username, password) => {
        // API endpoint
        const api_login_url = api_url + "/dj-rest-auth/login/";

        // Prepare the user data
        const userData = {
            username: username,
            password: password,
        };
    
        // Send a POST request to the API endpoint
        try {
            const response = await axios.post(api_login_url, userData, {
                withCredentials: true
              });
            console.log(response.data);
            // If login is successful, dispatch login action
            if(response.status === 200) {
                dispatch({ type: 'LOGIN' });
            }
        } catch (error) {
            console.error('There was an error!', error);
        }
    }

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle style={{backgroundColor: 'white', color: '#3a3a3a'}}>Login</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <form onSubmit={handleSubmit}>
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="dense"
                        label="Username"
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        value={username}
                        onChange={e => setUsername(e.target.value)}
                        required
                    />
                    <TextField
                        className={classes.input}
                        variant="outlined"
                        margin="dense"
                        label="Password"
                        InputLabelProps={{ shrink: true }}
                        type="password"
                        fullWidth
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                    />
                    <DialogActions className={classes.dialogActions}>
                        <Button type="submit" variant="contained" className={classes.loginButton}>
                            Login
                        </Button>
                    </DialogActions>
                    <DialogActions className={classes.dialogActions}>
                        <Button className={classes.signupButton} onClick={resetPassword}>Forgot Password</Button>
                        <Button onClick={handleOpenSignup} className={classes.signupButton}>or Sign up</Button>
                    </DialogActions>
                </form>
                <div className={classes.dividerContainer}>
                    <div className={classes.dividerBorder}></div>
                    <Typography variant="overline" className={classes.dividerText}>OR</Typography>
                    <div className={classes.dividerBorder}></div>
                </div>
                <Grid container direction="column" alignItems="center">
                    <GoogleLoginButton />
                </Grid>
            </DialogContent>
        </Dialog>
    );
};

export default Login;
