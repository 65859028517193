import React, { useState, useEffect, useContext } from 'react';
import { AuthContext } from './authContext'; 
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, makeStyles, Grid, Typography, Paper } from '@material-ui/core';
import axios from 'axios';
import Login from './Login';
import GoogleLoginButton from './GoogleLoginButton';

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        paddingTop: theme.spacing(2),
        backgroundColor: '#424242',
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    dialogActions: {
        padding: theme.spacing(2),
        justifyContent: 'center',
    },
    signupButton: {
        backgroundColor: '#87ceeb',
        '&:hover': {
            backgroundColor: '#6495ed',
        },
    },
    loginButton: {
        color: '#87ceeb',
        '&:hover': {
            color: '#6495ed',
        },
    },
    exampleContainer: {
        backgroundColor: '#555555',
        padding: theme.spacing(2),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
      },
    dividerContainer: {
        display: 'flex',
        alignItems: 'center',
        margin: theme.spacing(2, 0),
    },
    dividerBorder: {
        borderBottom: '1px solid white',
        width: '50%',
    },
    dividerText: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        color: 'white',
    },
}));

const SignupModal = ({ isOpen, onClose, openLoginModal }) => {
    const classes = useStyles();
    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [state, dispatch] = useContext(AuthContext);

    const [isLengthValid, setIsLengthValid] = useState(false);
    const [isLowercaseValid, setIsLowercaseValid] = useState(false);
    const [isUppercaseValid, setIsUppercaseValid] = useState(false);
    const [isNumberValid, setIsNumberValid] = useState(false);
    const [registrationError, setRegistrationError] = useState(''); // server error for email
    const api_url = process.env.REACT_APP_BACKEND_URL;

    const isFormValid = isLengthValid && isLowercaseValid && isUppercaseValid && isNumberValid;

    const handleLogin = async (username, password) => {
        const api_login = api_url + "/api/login/";

    
        const loginData = {
            username: username,
            password: password,
        };
    
        try {
            const response = await axios.post(api_login, loginData, {
                withCredentials: true
              });
            console.log(response.data);
            console.log("key: ", response.data.key);
            dispatch({ type: "LOGIN" });
            getUserInfo(response.data.key);
            // do something with response.data here, such as updating app state to indicate the user is logged in
        } catch (error) {
            console.error('There was an error!', error);
        }
    };


    const getUserInfo = async (key) => {
        axios.defaults.withCredentials = true;
        const api_username = api_url + "/api/username/";
        const config = {
            headers: {
                Authorization: `Token ${key}`,
            },
        };
    
        try {
            // const response = await axios.get(api_url, config);
            const response = await axios.get(api_username);
            console.log(response.data);
            // do something with response.data here
        } catch (error) {
            console.error('There was an error!', error);
        }
    }

    

    const handleSubmit = async (event) => {
        axios.defaults.withCredentials = false;
        event.preventDefault();
        const api_register = api_url + "/dj-rest-auth/registration/";
    
        // Prepare the user data
        const userData = {
            username: username,
            email: email,
            password1: password,
            password2: password,
        };
    
        // Send a POST request to the API endpoint
        try {
            const response = await axios.post(api_register, userData, {
                withCredentials: true
              });
            console.log(response.data);
            setRegistrationError(''); // clear email error if registration was successful
            handleLogin(username, password);
        } catch (error) {
            console.error('There was an error!', error);
            if (error.response && error.response.data) {
                // Django returns errors in a list, we will just grab the first one
                setRegistrationError(error.response.data);
            }
        }
    };


    

    const handleOpenLogin = () => {
        onClose();
        openLoginModal();
    };

    const validatePassword = (password) => {
        setIsLengthValid(password.length >= 8 && password.length <= 100);
        setIsLowercaseValid(/[a-z]/.test(password));
        setIsUppercaseValid(/[A-Z]/.test(password));
        setIsNumberValid(/[0-9]/.test(password));
    };

    const passwordValidation = () => (
        <Paper className={classes.exampleContainer}>
        <ul>
            <li style={{ color: isLengthValid ? '#00ff00' : 'black' }}>
                <Typography variant="caption">
                    Password must be between 8 and 100 characters long
                </Typography>
            </li>
            <li style={{ color: isLowercaseValid ? '#00ff00' : 'black' }}>
                <Typography variant="caption">
                    Password must include a lowercase letter
                </Typography>
            </li>
            <li style={{ color: isUppercaseValid ? '#00ff00' : 'black' }}>
                <Typography variant="caption">
                    Password must include an uppercase letter
                </Typography>
            </li>
            <li style={{ color: isNumberValid ? '#00ff00' : 'black' }}>
                <Typography variant="caption">
                    Password must include a number
                </Typography>
            </li>
            {registrationError && Object.keys(registrationError).map(key => (
            <li style={{ color: 'red' }}>
                <Typography variant="caption">
                    {key}: {registrationError[key].join(', ')}
                </Typography>
            </li>
        ))}
        </ul>
        </Paper>

    );
    

    return (
        <>
            <Dialog open={isOpen} onClose={onClose}>
                <DialogTitle style={{backgroundColor: 'white', color: '#3a3a3a'}}>Register</DialogTitle>
                <DialogContent className={classes.dialogContent}>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            margin="dense"
                            label="Username"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            value={username}
                            onChange={e => setUsername(e.target.value)}
                            required
                        />
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            margin="dense"
                            label="Email"
                            InputLabelProps={{ shrink: true }}
                            type="email"
                            fullWidth
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            className={classes.input}
                            variant="outlined"
                            margin="dense"
                            label="Password"
                            InputLabelProps={{ shrink: true }}
                            type="password"
                            fullWidth
                            value={password}
                            onChange={e => {
                                setPassword(e.target.value);
                                validatePassword(e.target.value);
                            }}
                            required
                        />
                        {passwordValidation()}
                        <DialogActions className={classes.dialogActions}>
                            <Button type="submit" variant="contained" className={classes.signupButton} disabled={!isFormValid}>
                                Register
                            </Button>
                        </DialogActions>
                    </form>
                    <div className={classes.dividerContainer}>
                        <div className={classes.dividerBorder}></div>
                            <Typography variant="overline" className={classes.dividerText}>OR</Typography>
                        <div className={classes.dividerBorder}></div>
                    </div>
                    <Grid container direction="column" alignItems="center">
                        <GoogleLoginButton />
                    </Grid>
                </DialogContent>
            </Dialog>
            <Login isOpen={isLoginOpen} onClose={() => setIsLoginOpen(false)} openRegisterModal={onClose}
             />
        </>
    );
};

export default SignupModal;
